<template>
  <div>
    <h4 class="mb-20 text-center">{{ $t("dashboard.travel") }}</h4>
    <div>
      <a-row :gutter="24">
        <a-col
          :span="24"
          :md="8"
          :xl="6"
          :xxl="3"
          class="mt-10"
          v-for="(tour, index) in tours"
          :key="index"
        >
          <a-card hoverable style="width: 240px; margin: 0 auto">
            <img
              slot="cover"
              alt="example"
              :src="require(`@/assets/images/${tour.image}`)"
              :style="{
                width: imgWidth + 'px',
                height: imgHeight + 'px',
                objectFit: imgFit,
              }"
            />
            <a-card-meta :title="tour.title"> </a-card-meta>
            <div class="mt-10">
              <a-button size="small" @click="moreDetail" type="primary">{{
                $t("action.MoreDetailButton")
              }}</a-button>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Travel",

  data() {
    return {
      imgWidth: 238.5,
      imgHeight: 160,
      imgFit: "cover", // or 'contain'
    };
  },

  computed: {
    tours() {
      return [
        {
          title: this.$t("label.ancientMystery"),
          image: "ancient-mysteries.jpg",
        },
        {
          title: this.$t("label.buddhismAndBelief"),
          image: "golden-buddha.jpg",
        },
        {
          title: this.$t("label.theSecretWar"),
          image: "kids-bombs.jpg",
        },
        {
          title: this.$t("label.wondersOfNature"),
          image: "kuang-si-falls.jpg",
        },
        {
          title: this.$t("label.authenticCulture"),
          image: "Laos-Traditional.jpg",
        },
        {
          title: this.$t("label.legendaryLandmarks"),
          image: "Buddha-Park.jpg",
        },
      ];
    },
  },

  mounted() {},

  methods: {
    moreDetail() {
      const url = "https://www.tourismlaos.org/";
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped></style>
